import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import * as ics from "ics";
import { saveAs } from "file-saver";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
export var downloadEvent = function downloadEvent(_ref) {
  var start = _ref.start,
      end = _ref.end,
      title = _ref.title,
      description = _ref.description,
      location = _ref.location,
      url = _ref.url,
      geo = _ref.geo,
      categories = _ref.categories,
      status = _ref.status,
      organizer = _ref.organizer,
      attendees = _ref.attendees;

  /*const event = {
    start: [2018, 5, 30, 6, 30],
    end: [2018, 5, 30, 6, 30],
    title: 'Bolder Boulder',
    description: 'Annual 10-kilometer run in Boulder, Colorado',
    location: 'Folsom Field, University of Colorado (finish line)',
    url: 'http://www.bolderboulder.com/',
    geo: { lat: 40.0095, lon: 105.2669 },
    categories: ['10k races', 'Memorial Day Weekend', 'Boulder CO'],
    status: 'CONFIRMED',
    busyStatus: 'BUSY',
    organizer: { name: 'Admin', email: 'Race@BolderBOULDER.com' },
    attendees: [
      { name: 'Adam Gibbons', email: 'adam@example.com', rsvp: true, partstat: 'ACCEPTED', role: 'REQ-PARTICIPANT' },
      { name: 'Brittany Seaton', email: 'brittany@example2.org', dir: 'https://linkedin.com/in/brittanyseaton', role: 'OPT-PARTICIPANT' }
    ]
  }*/
  var event = {
    start: start,
    end: end,
    title: title,
    description: description,
    location: location,
    url: url,
    geo: geo,
    categories: categories,
    status: status,
    organizer: organizer,
    attendees: attendees,
    busyStatus: "BUSY"
  };
  ics.createEvent(event, function (error, value) {
    if (error) {
      ErrorEventBus.$emit("update:error", InitializeError(error));
      return false;
    }

    var blob = new Blob([value], {
      type: "data:text/calendar;charset=utf8"
    });
    saveAs(blob, title + ".ics");
  });
};